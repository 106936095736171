/*---------------------------------------------------
    LESS Elements 0.9
  ---------------------------------------------------
    A set of useful LESS mixins
    More info at: http://lesselements.com
  ---------------------------------------------------*/
@mixin gradient-corner($color:#F5F5F5, $start: #EEE, $stop: #FFF){
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,7db9e8+100 */
  background: $color;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, $start 0%, $stop 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, $start 0%, $stop 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, $start 0%, $stop 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  //filter: progid:DXImageTransform.Microsoft@include gradient( startColorstr=  '$start', endColorstr='$stop',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}

@mixin gradient($color:#F5F5F5, $start: #EEE, $stop: #FFF){
  background: $color;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, $start), color-stop(1, $stop));
  background: -ms-linear-gradient(bottom, $start, $stop);
  background: -moz-linear-gradient(center bottom, $start 0%, $stop 100%);
  background: -o-linear-gradient($stop, $start);
  //filter: e(%(  "progid:DXImageTransform.Microsoft@include gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",$stop,$start));
}

@mixin bw-gradient($color:#F5F5F5, $start: 0, $stop: 255){
  background: $color;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, rgb($start,$start,$start)), color-stop(1, rgb($stop,$stop,$stop)));
  background: -ms-linear-gradient(bottom, rgb($start,$start,$start) 0%, rgb($stop,$stop,$stop) 100%);
  background: -moz-linear-gradient(center bottom, rgb($start,$start,$start) 0%, rgb($stop,$stop,$stop) 100%);
  background: -o-linear-gradient(rgb($stop,$stop,$stop), rgb($start,$start,$start));
  //filter: e(%(  "progid:DXImageTransform.Microsoft@include gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",rgb($stop,$stop,$stop),rgb($start,$start,$start)));
}

@mixin bordered($top-color:#EEE, $bottom-color: #EEE, $left-color: #EEE, $right-color: #EEE){
  border-top: solid 1px $top-color;
  border-left: solid 1px $left-color;
  border-right: solid 1px $right-color;
  border-bottom: solid 1px $bottom-color;
}

@mixin drop-shadow($x-axis:0, $alpha:0.1, $blur:2px, $y-axis:1px){
  -webkit-box-shadow: $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
  -moz-box-shadow: $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
  box-shadow: $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
}

@mixin rounded($radius:2px){
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius($topright:0, $bottomleft: 0, $bottomright: 0, $topleft: 0){
  -webkit-border-top-right-radius: $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius: $bottomleft;
  -webkit-border-top-left-radius: $topleft;
  -moz-border-radius-topright: $topright;
  -moz-border-radius-bottomright: $bottomright;
  -moz-border-radius-bottomleft: $bottomleft;
  -moz-border-radius-topleft: $topleft;
  border-top-right-radius: $topright;
  border-bottom-right-radius: $bottomright;
  border-bottom-left-radius: $bottomleft;
  border-top-left-radius: $topleft;
  @include background-clip(padding-box);
}

@mixin opacity($opacity:0.5){
  -moz-opacity: $opacity;
  -khtml-opacity: $opacity;
  -webkit-opacity: $opacity;
  opacity: $opacity;
  $opperc: $opacity * 100;
  -ms-filter:  "progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})";
  filter:  "alpha(opacity=${opperc})";
}

@mixin transition-duration($duration:0.2s){
  -moz-transition-duration: $duration;
  -webkit-transition-duration: $duration;
  -o-transition-duration: $duration;
  transition-duration: $duration;
}

@mixin transform($arguments){
  -webkit-transform: $arguments;
  -moz-transform: $arguments;
  -o-transform: $arguments;
  -ms-transform: $arguments;
  transform: $arguments;
}

@mixin rotation($deg:5deg){
  @include transform(rotate($deg));
}

@mixin scale($ratio:1.5){
  @include transform(scale($ratio));
}

@mixin transition($duration:0.2s, $ease:ease-out){
  -webkit-transition: all $duration $ease;
  -moz-transition: all $duration $ease;
  -o-transition: all $duration $ease;
  transition: all $duration $ease;
}

@mixin inner-shadow($horizontal:0, $alpha:0.4, $blur:2px, $vertical:1px){
  -webkit-box-shadow: inset $horizontal $vertical $blur rgba(0, 0, 0, $alpha);
  -moz-box-shadow: inset $horizontal $vertical $blur rgba(0, 0, 0, $alpha);
  box-shadow: inset $horizontal $vertical $blur rgba(0, 0, 0, $alpha);
}

@mixin box-shadow($arguments){
  -webkit-box-shadow: $arguments;
  -moz-box-shadow: $arguments;
  box-shadow: $arguments;
}

@mixin box-sizing($sizing:border-box){
  -ms-box-sizing: $sizing;
  -moz-box-sizing: $sizing;
  -webkit-box-sizing: $sizing;
  box-sizing: $sizing;
}

@mixin user-select($argument:none){
  -webkit-user-select: $argument;
  -moz-user-select: $argument;
  -ms-user-select: $argument;
  user-select: $argument;
}

@mixin columns($colwidth:250px, $colcount: 0, $colgap: 50px, $columnRuleColor: #EEE, $columnRuleStyle: solid, $columnRuleWidth: 1px){
  -moz-column-width: $colwidth;
  -moz-column-count: $colcount;
  -moz-column-gap: $colgap;
  -moz-column-rule-color: $columnRuleColor;
  -moz-column-rule-style: $columnRuleStyle;
  -moz-column-rule-width: $columnRuleWidth;
  -webkit-column-width: $colwidth;
  -webkit-column-count: $colcount;
  -webkit-column-gap: $colgap;
  -webkit-column-rule-color: $columnRuleColor;
  -webkit-column-rule-style: $columnRuleStyle;
  -webkit-column-rule-width: $columnRuleWidth;
  column-width: $colwidth;
  column-count: $colcount;
  column-gap: $colgap;
  column-rule-color: $columnRuleColor;
  column-rule-style: $columnRuleStyle;
  column-rule-width: $columnRuleWidth;
}

@mixin translate($x:0, $y:0){
  @include transform(translate($x, $y));
}

@mixin background-clip($argument:padding-box){
  -moz-background-clip: $argument;
  -webkit-background-clip: $argument;
  background-clip: $argument;
}
