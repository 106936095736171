section.services {
  &::before,
  &::after {
  flex-basis: 0;
  order: 1;
}
  background: $color-base;
  .row {
    padding: 0;
    margin: 0;

    .grid-item {
      padding: 0 !important;
      margin: 0 !important;
      line-height: 100%;
      font-size: 0;
      background-size: cover;
      background-position: center center;

      h3 {
        margin: 0;
        padding: 0;
        line-height: 100%;
      }

      a {
        background-color: rgba(28, 33, 35, .6);
        display: inline-block;
        min-height: 300px;
        box-sizing: border-box;
        padding: 30px;
        margin: 0;
        color: #fff;
        @include flex-it;
        @include flex-align-item-center;
        @include flex-justify-center;
        -webkit-transition: background-color .2s ease-out;
        -moz-transition: background-color .2s ease-out;
        -o-transition: background-color .2s ease-out;
        transition: background-color .2s ease-out;
        @include media-breakpoint-down(xs) {
          min-height: 200px;
        }
        &:hover {
          background-color: $color-overlay-rgba;
        }

      }
    }
  }
}
