

//
// Custom
//
div.flexslider {
  margin-bottom: -10px;
  display: block;

  .flex-control-paging li a {
    background: $color-base;
    @include opacity(.2);
  }

  .flex-control-paging li a:hover {
    @include opacity(1);
    background: $color-accent-secondary;
    border-color: $color-accent-secondary;
  }

  .flex-control-paging li a.flex-active {
    @include opacity(1);
    background: $color-accent-secondary;
    border-color: $color-accent-secondary;
  }

  .flex-direction-nav a:before {
    background: url("#{$images}/arrow-left.png") top center no-repeat;
    background-size: 35px 68px;
    @include opacity(.3);
    @include transition();

    @media handheld, only screen and (max-width: 768px) {
      display: none;
    }
  }

  .flex-direction-nav a:hover:before {
    @include opacity(1);
  }

  .flex-direction-nav a.flex-next:before {
    background: url("#{$images}/arrow-left.png") top center no-repeat;
    background-size: 35px 68px;
    @include rotation(180deg);
  }

  ul.slides {
    li {
      &, p, h1, h2, h3, h4, h5, h6 {
        //color: #fff;
      }

      //@include opacity(.4);
      background-size: cover;
      background-position: center center;
      padding: 50px 0;
      .content {
        width: 65%;
        margin: 0 auto;
        @media handheld, only screen and (max-width: 768px) {
          width: auto;
          margin: 0 25px;
        }
      }
      &.loaded {
        .overlay {
          //background: transparent;
        }
      }
    }
  }
}



.fcslider {
  background: #F2F9F2;
  padding: 40px 0;

  .flexslider {
    &, li {
      padding-top: 0 !important;
    }
    .flex-direction-nav a:before {
      background: url("#{$images}/arrow-left-dark.png") top center no-repeat;
      background-size: 35px 68px;
      @include opacity(.3);
      @include transition();

      @media handheld, only screen and (max-width: 768px) {
        display: none;
      }
    }
    .flex-direction-nav a.flex-next:before {
      background: url("#{$images}/arrow-left-dark.png") top center no-repeat;
      background-size: 35px 68px;
      @include rotation(180deg);
    }
  }
}
