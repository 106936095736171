// Search form
.search-form {
  //@extend .form-inline;
}
.search-form label {
  font-weight: normal;
  //@extend .form-group;
}
.search-form .search-field {
  //@extend .form-control;
}
.search-form .search-submit {
  //@extend .btn;
  //@extend .btn-secondary;
}



.budget {
  //@include flex-it;
  #budget-value-display {
    font-family: $font-headline;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 28px;
  }
}


.gform_wrapper {
  .gform_body {
    .gform_fields {
      // checkboxes
      ul.gfield_checkbox {
        li {
          display: block;
          vertical-align: middle;
          font-size: 0;
          margin: 10px 0;
          padding: 3px 0 !important;
          width: auto;
          height: auto;
          position: static;

          input {
            margin: 0;
            padding: 0;
          }
          input:checked + label {
            font-weight: normal;
          }
          label {
            line-height: 100%;
            margin: 0;
            padding: 0 5px;
          }
        }
      }
    }
  }
}





/* =================================================
     Form styles
   ============================================== */
.gform_wrapper .checkboxes label {
  float: left;
  margin: 5px 15px 5px 0;
  line-height: 120%;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, .15);
  @include transition();
}

:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, .15);
  @include transition();
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, .15);
  @include transition();
}

:-ms-input-placeholder {
  color: rgba(0, 0, 0, .15);
  @include transition();
}

.gform_wrapper .gform_body label.gfield_label {
  //@include sans-modified;
  font-size: 17px;
  margin: 0 0 5px;
  font-weight: 400;
  display: block;
  line-height: 130%;
}
html.js .gform_wrapper .gform_body label.gfield_label {
  margin-bottom: 4px;
  font-size: 19px;
}

html .gform_wrapper .gform_body .gfield .ginput_complex {
  label {
    color: $color-light-text-forms;
    font-size: 15px;
  }
}

.gform_wrapper h3 {
  margin-bottom: 0.5em;
  font-size: 19px;
  //@include sans-modified;
}

html body .gform_wrapper .gform_body .ginput_container input,
html body .gform_wrapper .gform_body .ginput_container textarea,
html body input,
html body textarea {
  margin: 0;
  box-sizing: border-box;
  @include rounded(2px);
  font-size: 17px;
  color: $color-base;
  line-height: 170%;
  @include inner-shadow(none);
  @include box-shadow(none);
  border: 0;
  //background-color: #E0E0E0;
  background-color: #fff;
  border: 1px solid $color-hr;
  font-weight: 400;
  padding: 6px 9px;
}

.gform_wrapper .gform_body input,
.gform_wrapper .gform_body textarea {
  width: 100%;
}

.gform_wrapper {
  padding-bottom: 30px;
}
// Change color of placeholder on focus.

.gform_wrapper .gform_body input:focus::-webkit-input-placeholder {
  //color: rgba(255, 255, 255, .15);
}

.gform_wrapper .gform_body input:focus:-moz-placeholder {
  /* Firefox 18- */
  //color: rgba(255, 255, 255, .15);
}

.gform_wrapper .gform_body input:focus::-moz-placeholder {
  /* Firefox 19+ */
  //color: rgba(255, 255, 255, .15);
}

.gform_wrapper .gform_body input:focus:-ms-input-placeholder {
  //color: rgba(255, 255, 255, .15);
}

.gform_wrapper .gform_body input:focus,
.gform_wrapper .gform_body textarea:focus::-webkit-input-placeholder {
  //color: rgba(255, 255, 255, .15);
}

.gform_wrapper .gform_body input:focus,
.gform_wrapper .gform_body textarea:focus:-moz-placeholder {
  /* Firefox 18- */
  //color: rgba(255, 255, 255, .15);
}

.gform_wrapper .gform_body input:focus,
.gform_wrapper .gform_body textarea:focus::-moz-placeholder {
  /* Firefox 19+ */
  //color: rgba(255, 255, 255, .15);
}

.gform_wrapper .gform_body input:focus,
.gform_wrapper .gform_body textarea:focus:-ms-input-placeholder {
  //color: rgba(255, 255, 255, .15);
}

.gform_wrapper .gform_body textarea:focus,
html body .gform_wrapper .gform_body input:focus {}

.gform_wrapper .gform_body ul li {
  margin-bottom: 50px;
}

.gform_wrapper .gform_body label.gfield_label .gfield_required {
  color: $color-accent;
}

.gform_wrapper .gform_body .gfield_description {
  font-family: $font-serif;
  font-style: italic;
  margin-top: 3px;
  padding: 0;
  color: $color-light-text;
}

.gform_wrapper ul.gfield_checkbox li {
  display: inline-block;
  margin: 5px 30px 0 0;
}

.gform_wrapper ul.gfield_checkbox li label {
  font-size: 15px;
}

.gform_wrapper ul.gfield_checkbox li input[type=checkbox]:checked + label {
  font-weight: 100;
}

.gform_wrapper .gform_body .gform_drop_area {
  padding: 40px;
  @include rounded(2px);
  background-color: $color-base;
  border: none;
}

.gform_wrapper .gform_body .ginput_container input.gform_button_select_files {
  width: auto;
  display: block;
  margin: 30px auto 0;
  padding: 15px 30px;
  //@include sans-modified;
  font-size: 13px;
  border: 0;
  color: #fff;
  background-color: $color-accent;
  border: none;
  @include transition();
}

.gform_wrapper .gform_body .ginput_container input.gform_button_select_files:hover {}

.gform_confirmation_wrapper {
  text-align: center;
}

body .gform_wrapper li.gfield.gfield_error,
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  background-color: transparent;
  margin-bottom: 0;
  padding: 0;
  border: 0;
}

body .gform_wrapper li.gfield.gfield_error input,
body .gform_wrapper li.gfield.gfield_error textarea {
  border: 2px solid #CA0500;
}

body .gform_wrapper .gform_body .gform_fields .gfield_error .validation_message {
  color: #CA0500;
  //@include sans-modified;
  font-style: normal;
  padding: 0;
  margin: 0;
}

body .gform_wrapper .validation_error {
  color: #CA0500;
  border: 0;
}

.gform_wrapper .gform_body li.fl-left,
.gform_wrapper .gform_body li.fl-right {
  display: inline-block;
  width: 48%;
  padding: 0;
  float: left;
  clear: none;
}

.gform_wrapper .gform_body li.fl-left {
  margin-right: 20px;
}

.gform_wrapper .gform_body li.fl-right {
  clear: none;
}
@media handheld, only screen and (max-width: 767px) {
  .gform_wrapper .gform_body li.fl-left,
  .gform_wrapper .gform_body li.fl-right {
    width: 100%;
    padding: 0;
    float: none;
    clear: both;
  }
}
/* 3 column floater */
.gform_wrapper .gform_body li.fl3 {
  display: inline-block;
  width: 31.2%;
  margin-right: 2%;
  padding: 0;
  float: left;
  clear: none;
  @media handheld, only screen and (max-width: 767px) {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}

.gform_wrapper .gform_body li.fl3.last {
  margin-right: 0;
}

html body .gform_wrapper .chosen-container-multi .chosen-choices {
  @include rounded(0);
  border: 0;
}

html body footer .gform_wrapper .chosen-container-multi .chosen-choices li.search-field input[type=text] {
  height: auto;
  padding: 9px;
}

html body .gform_wrapper .chosen-container .chosen-results li.highlighted {
  background-color: $color-accent;
  background-image: none;
  color: #fff;
}

html body .gform_wrapper .chosen-container-multi .chosen-choices li.search-field .default {
  color: $color-body-grey;
}

html body .gform_wrapper .chosen-container-active .chosen-choices,
html body .gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single,
html body .gform_wrapper .chosen-container-multi .chosen-choices,
html body .gform_wrapper .chosen-container-multi .chosen-choices li.search-choice,
html body .gform_wrapper .chosen-container-single .chosen-single {
  background-image: none;
  color: $color-base;
  background-color: #fff;
  border: 1px solid $color-hr;
  @include inner-shadow(none);
  @include box-shadow(none);
  padding: 12px;
  @include rounded(2px);
  font-weight: 300;
  font-size: 18px;
  height: auto;
}

html body .gform_wrapper .chosen-container-multi .chosen-choices .search-choice-focus {
  background-color: #fff;
  background-image: none;
}

html body .gform_wrapper .chosen-container .chosen-drop {
  position: absolute;
  top: 100%;
  left: -9999px;
  z-index: 1010;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  border: 0;
  border-top: 0;
  background: #fff;
  box-shadow: none;
  @include rounded(0 0 2px 2px);
  @include box-shadow(1px 1px 4px rgba(0, 0, 0, .2));
}

html body .gform_wrapper .chosen-container .chosen-results-scroll-down span,
html body .gform_wrapper .chosen-container .chosen-results-scroll-up span,
html body .gform_wrapper .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
html body .gform_wrapper .chosen-container-single .chosen-single abbr,
html body .gform_wrapper .chosen-container-single .chosen-single div b,
html body .gform_wrapper .chosen-rtl .chosen-search input[type="text"] {
  background-image: none !important;
}

html body .form .gform_footer {
  .button.gform_button {
    @include butt;
  }

  .button.gform_button:hover {
    @include butt-hover;
  }
}

html body .gform_wrapper .gform_body li.gsection {
  clear: both;
  padding-top: 30px;

  h2.gsection_title {
    color: $color-accent;
    display: block;
    margin-top: 0;
  }
}

html body .gform_wrapper .gform_body li.gsection:first-child {
  padding-top: 0;
}
