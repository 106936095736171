


.google-map {
  width: 100%;
	height: 400px;
	border: #ccc solid 1px;
	margin: 20px 0;
  img {
     max-width: inherit !important;
  }
}
