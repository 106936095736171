.footer.footer {
  background: $color-base;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0;

  .footer-inner {
    padding-top: 40px;
    padding-bottom: 40px;
    background: rgba(32, 39, 51, .85);
  }
  &, p, ul li {
    font-size: 14px; 
  }
  &, p, h3, h4, h5, ul li {
    color: #fff;
  }

  p {
    margin: 0;
  }
  a.butt {
    padding: 12px;
  }


  .left {
    ul {
      list-style: none;
      padding-left: 0;
      li {
        background: url("#{$images}/icon-green-check.png") left center no-repeat;
        background-size: 23px auto;
        padding: 8px 0 8px 30px;
        margin-left: 0;
        @media handheld, only screen and (max-width: 768px) {
          display: inline-block;
          margin: 0 15px;
        }
      }
    }
    .sn {
      //justify-content: center;
    }
    @media handheld, only screen and (max-width: 768px) {
      text-align: center;
    }
  }

  .sn {
    margin-top: 20px;
    @include flex-justify-center;
  }

  .legal {
    margin-top: 90px;
    //text-transform: uppercase;
    //letter-spacing: .09em;
    font-size: 14px;
    text-align: center;
    &, a {
      color: rgba(255, 255, 255, .3);
    }
  }
}
