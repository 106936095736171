
.testimonials.full-list {
  .testimonial {
    margin-bottom: 50px;
    //border: 1px solid $color-hr;
    @include drop-shadow(0, 0.1, 5px, 1px);
    @include rounded(3px);
    padding: 20px;
    background-color: #fff;
    @include media-breakpoint-down(sm) {
      padding: 10px;
    }
  }
}



.testimonial-slider {
  background: $color-accent;
  padding-top: 50px;
  text-align: center;
  h1,h2,h3 {
    color: #fff;
    margin: 0;
    text-align: center;
  }

  .carousel-indicators {
    display: none;
  }
  .carousel.slide {

    .carousel-inner {
      .overlay, .carousel-inner {
        min-height: 400px;
        @include media-breakpoint-down(sm) {
          min-height: 500px;
        }
      }
      .overlay {
        background: $color-accent;
      }
      .content {
        box-sizing: border-box;;
        max-width: 1000px;
        padding: 0 30px 30px 30px;
        margin: 0 auto 30px;
        text-align: left;
        background: transparent;

        @include flex-it;
        @include flex-align-item-center;
        @include flex-justify-center;

        .thumb {
          padding-right: 20px;
          width: 30%;
          @include media-breakpoint-down(sm) {
            width: 100%;
          }
          img {
            width: 100%;
            height: auto;
            max-width: 300px;
            margin: 0 auto;
            display: block;
            @include media-breakpoint-down(sm) {
              max-width: 200px;
            }
          }
        }

        a {
          color: #fff;
          border-bottom: 1px solid $color-accent;

          &:hover {
            border-color: #fff;
          }
        }

        .the-testimonial {
          width: 70%;
          @include media-breakpoint-down(sm) {
            width: 100%;
          }
        }

        p, .author, .author-subline {
          color: #fff;
        }

        @include media-breakpoint-down(sm) {
          display: block;
        }

      }
      .carousel-item {
        background-size: cover;
      }
    }

    a.carousel-control-prev, a.carousel-control-next {
      background: $color-base;
    }
  }


}
