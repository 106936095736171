
/* =================================================
  Mixins
============================================== */
@mixin flex-it {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -moz-box-wrap: nowrap;
    -webkit-box-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flexbox-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
@mixin flex-row {
    -moz-box-direction: row;
    -webkit-box-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flexbox-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
@mixin flex-col {
    -moz-box-direction: column;
    -webkit-box-direction: column;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flexbox-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
@mixin flex-align-between {
    -webkit-box-align-content: space-between;
    -webkit-align-content: space-between;
    -ms-flex-align-content: space-between;
    align-content: space-between;
}
@mixin flex-align-center {
    -webkit-box-align-content: center;
    -webkit-align-content: center;
    -ms-flex-align-content: center;
    align-content: center;
}
@mixin flex-align-start {
    -webkit-box-align-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-align-content: flex-start;
    align-content: flex-start;
}
@mixin flex-align-item-start {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -moz-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
}
@mixin flex-align-item-end {
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -moz-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
}
@mixin flex-align-item-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@mixin flex-start-all {
    -webkit-box-pack: justify;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: justify;
    -moz-box-pack: justify;
    justify-content: flex-start;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -moz-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-align-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-align-content: flex-start;
    align-content: flex-start;
}
@mixin flex-align-item-stretch {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -moz-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
@mixin flex-justify-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    -moz-box-pack: justify;
    justify-content: space-between;
}
@mixin flex-justify-center {
    -webkit-box-pack: justify;
    -webkit-justify-content: center;
    -ms-flex-pack: justify;
    -moz-box-pack: justify;
    justify-content: center;
}
@mixin flex-justify-start {
    -webkit-box-pack: justify;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: justify;
    -moz-box-pack: justify;
    justify-content: flex-start;
}
@mixin flex-justify-end {
    -webkit-box-pack: justify;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: justify;
    -moz-box-pack: justify;
    justify-content: flex-end;
}
@mixin flex-wrap {
    -moz-box-wrap: wrap;
    -webkit-box-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flexbox-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
@mixin flex-item-auto {
    -webkit-box-basis: auto;
    -webkit-flex-basis: auto;
    -ms-flex-basis: auto;
    flex-basis: auto;
    -webkit-box-flex: 1;
    /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-flex: 1;
    /* OLD - Firefox 19- */
    -webkit-flex: 1;
    /* Chrome */
    -ms-flex: 1 0 auto;
    /* IE 10 */
    flex: 1;
}
