.carousel.slide {
  background: $color-base;
  max-width: 2200px;
  margin: 0 auto;
  padding-bottom: 0;
  .carousel-inner {
    .overlay, .carousel-inner {
      min-height: 500px;
      padding: 0;
    }
    .overlay {
      background: rgba(0,0,0,.5);
      background: linear-gradient(90deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .53), rgba(0,0,0,.3));
      @include flex-it;
      @include flex-align-item-center;
      @include flex-justify-start;
    }
    .content {
      max-width: 550px;
      padding: 20px;
      padding-left: 50px;
      margin: 0 0;
      text-align: left;
      background: rgba(0,0,0,.5);
      margin-left: 50px;

      @include media-breakpoint-down(sm) {
        padding-left: 30px;
        margin-left: 0;
      }

    }
    .carousel-item {
      background-size: cover;
    }
  }

  a.carousel-control-prev, a.carousel-control-next {
    width: 50px;
    height: 50px;
    background: $color-accent;
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
    position: absolute !important;
    @include opacity(.8);
    &:hover {
      @include opacity(1);
    }
  }
  a.carousel-control-prev {
    right: 50px;
  }
}



.carousel {
  h1, h2, h3, h4, p {
    color: #fff;
  }
  p {
    position: static !important;
    display: block;
  }
}
