//
// Icons
//
.icon {
  margin: 0 auto;
  margin-bottom: 15px;
}






.molecule {
  cursor: pointer;
  width: 75px;
  margin: 0 auto;
  .st0 {
    fill: none;
    stroke: #698381;
    stroke-width: 1;
  }
	.st1 {
    fill:$color-accent;
  }
	.st2 {
    fill: #fff;
    //fill: rgba(255, 255, 255, .15)
  }

  #nucleus {
    @include transition();
  }


  #orbits {
    @include transition();
    @include opacity(.8);
    ellipse {
      @include opacity(.7);
      animation: orbit-fade 8s linear infinite;
    }
    ellipse:first-child {
      animation: orbit-fade 4.5s linear infinite;
      animation-delay: 1s;
    }
    ellipse:last-child {
      animation: orbit-fade 3s linear infinite;
    }
  }


  #electrons {
    .st0 {
      fill: none;
      stroke: $color-accent;
      //@include opacity(.3);
      stroke-width: 3;
      stroke-miterlimit: 3;
      stroke-dasharray: .4, 90;
      stroke-linecap: round;
      stroke-dashoffset: 15000;
    }

    ellipse:first-child {
      stroke-dashoffset: 20000;
    }
    ellipse:last-child {
      stroke-dashoffset: 3700;
      stroke-dasharray: .4, 200;
    }
  }

    &:hover {
      #orbits {
        @include opacity(1);
      }
      #electrons {
        .st0 {
          stroke: #FBAE42;
        }
      }
      #nucleus {
        fill: #FBAE42;
      }
    }


  & {
    #electrons {
      .st0 {
        animation: dash 200s linear infinite;
      }

      ellipse:first-child {
        animation: dash 200s linear infinite;
      }
      ellipse:last-child {
        animation: dash 100s linear infinite;
      }
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes orbit-fade {

  0%  {
    @include opacity(.3);
  }
  25%  {
    @include opacity(.75);
  }
  50%  {
    @include opacity(.95);
  }
  75%  {
    @include opacity(.85);
  }
  100%  {
    @include opacity(.35);
  }
}
