.instagram.loaded {
  padding-bottom: 0;
}

.instagram.loaded #insta_wrapper {
  @include opacity(1);
}

.instagram {
  margin-top: 0;
  padding-bottom: 50%;
  background-color: $color-base;
  //border: 10px solid $color-accent;
  .sn {
    margin-top: 6px;
  }

  #insta_wrapper {
    @include opacity(0);
    @include transition();
    @include flex-it;
    flex-flow: row wrap;


    img {
      width: 100%;
      display: block;
      @include rounded(0);
      margin: 0;
      padding: 0;
      line-height: 0;
    }

    video {
      display: block;
      width: 100%;
      @include rounded(0);
      margin: 0;
      padding: 0;
      line-height: 0;
      overflow: hidden;
    }
    div.ig:after {
        content: "";
        float:left;
        display: block;
        padding-top: 100%;
    }
    div.ig {
      flex: 1 0 auto;
      position: relative;
      background-color: $color-base;
      overflow: hidden;
      //width: 25%;
      flex-basis: 25%;

      @media handheld, only screen and (max-width: 768px) {
        flex-basis: 50%;
      }

      .likes {
        position: relative;
        z-index: 100;
        @include opacity(0);
        b {
          display: block;
          position: absolute;
          top: 7px;
          left: 7px;
          color: rgba(255, 255, 255, .7);
          font-size: 13px;
          text-transform: uppercase;
          letter-spacing: .1em;
          font-weight: 400;
        }
      }
      a:hover .likes {
        @include opacity(1);
      }


      .inner {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
      }

      img,
      video {

        @include opacity(.8);
        @include transition();

        &:hover {
          @include opacity(1);
        }
      }

      a:hover img,
      a:hover video {
        @include opacity(1);
      }

      @media handheld, only screen and (max-width: 768px) {
        width: 100%;

        &#ig-10,
        &#ig-5,
        &#ig-6,
        &#ig-7,
        &#ig-8,
        &#ig-9 {
          display: none;
        }
      }
    }
  }
}

.show-us-tag {
  position: relative;
  width: 100%;
  z-index: 100;
  text-align: center;
  height: 1px;
  margin: 0 auto;
  top: -20px;
  height: 0;

  &,
  & h3 {
    width: 280px;
  }

  h1,
  h2,
  h3,
  h4 {
    color: #fff;
  }

  hr.mini {
    margin: 15px auto;
    border-color: #fff;
  }

  a:active,
  a:hover,
  a:link,
  a:visited {
    color: #fff;
  }

  a:hover {
    color: rgba(255, 255, 255, .6);
  }

  h3 {
    background: $color-base;
    background: rgba(27, 42, 69, .6);
    position: absolute;
    font-size: 30px;
    line-height: 100%;
    text-transform: none;
    padding: 15px;
    //@include rotation(-3deg);
    span {
      display: block;
      margin: 0 0 5px;
      font-size: 13px;
      line-height: 100%;
      font-weight: 200;
      font-family: $font-body;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      margin-right: -5px;
    }
  }
}
